@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
a {
    text-decoration: none;
    color: inherit;
}
button[data-tid="banner-decline"] {
    background-color: black !important;
    border: none !important;
}


@import url("https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLfI4Xm_M2hDUmvx2RnpH6d807i6mDOseiAkWU_5C_iF2");
ol.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_14-1 > li:before {
  content: " ";
}
.lst-kix_list_14-3 > li:before {
  content: " ";
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
.lst-kix_list_14-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_14-4 > li:before {
  content: " ";
}
ul.lst-kix_list_28-1 {
  list-style-type: none;
}
ol.lst-kix_list_30-0.start {
  counter-reset: lst-ctn-kix_list_30-0 0;
}
.lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}
.lst-kix_list_14-5 > li:before {
  content: " ";
}
.lst-kix_list_14-7 > li:before {
  content: " ";
}
.lst-kix_list_14-6 > li:before {
  content: " ";
}
.lst-kix_list_30-6 > li {
  counter-increment: lst-ctn-kix_list_30-6;
}
ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}
.lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_list_7-5 {
  list-style-type: none;
}
ul.lst-kix_list_28-2 {
  list-style-type: none;
}
ol.lst-kix_list_7-6 {
  list-style-type: none;
}
ul.lst-kix_list_28-3 {
  list-style-type: none;
}
ol.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_28-4 {
  list-style-type: none;
}
ol.lst-kix_list_7-8 {
  list-style-type: none;
}
ul.lst-kix_list_28-5 {
  list-style-type: none;
}
ol.lst-kix_list_7-1 {
  list-style-type: none;
}
ul.lst-kix_list_28-6 {
  list-style-type: none;
}
ol.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_28-7 {
  list-style-type: none;
}
ol.lst-kix_list_7-3 {
  list-style-type: none;
}
ul.lst-kix_list_28-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_14-2 > li:before {
  content: " ";
}
ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}
ul.lst-kix_list_17-1 {
  list-style-type: none;
}
ul.lst-kix_list_17-0 {
  list-style-type: none;
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
ol.lst-kix_list_26-0 {
  list-style-type: none;
}
ul.lst-kix_list_17-8 {
  list-style-type: none;
}
ul.lst-kix_list_17-7 {
  list-style-type: none;
}
ul.lst-kix_list_17-6 {
  list-style-type: none;
}
ul.lst-kix_list_17-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
ul.lst-kix_list_17-4 {
  list-style-type: none;
}
ul.lst-kix_list_17-3 {
  list-style-type: none;
}
ol.lst-kix_list_30-5.start {
  counter-reset: lst-ctn-kix_list_30-5 0;
}
.lst-kix_list_14-8 > li:before {
  content: " ";
}
ul.lst-kix_list_17-2 {
  list-style-type: none;
}
ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}
.lst-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
}
ol.lst-kix_list_6-0 {
  list-style-type: none;
}
ol.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}
.lst-kix_list_24-7 > li:before {
  content: " ";
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ul.lst-kix_list_27-1 {
  list-style-type: none;
}
.lst-kix_list_24-8 > li:before {
  content: " ";
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ul.lst-kix_list_27-2 {
  list-style-type: none;
}
.lst-kix_list_5-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_5-3, upper-latin) ") ";
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
.lst-kix_list_5-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_5-2, lower-roman) ") ";
}
.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}
.lst-kix_list_5-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_5-1, lower-latin) ") ";
}
.lst-kix_list_24-2 > li:before {
  content: " ";
}
.lst-kix_list_5-7 > li:before {
  content: " ";
}
.lst-kix_list_5-6 > li:before {
  content: " ";
}
.lst-kix_list_5-8 > li:before {
  content: " ";
}
.lst-kix_list_24-3 > li:before {
  content: " ";
}
.lst-kix_list_24-4 > li:before {
  content: " ";
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
ol.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_27-3 {
  list-style-type: none;
}
.lst-kix_list_24-5 > li:before {
  content: " ";
}
ol.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_27-4 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_5-4, decimal) ") ";
}
ol.lst-kix_list_6-8 {
  list-style-type: none;
}
ul.lst-kix_list_27-5 {
  list-style-type: none;
}
ul.lst-kix_list_27-6 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: " ";
}
ol.lst-kix_list_6-2 {
  list-style-type: none;
}
ul.lst-kix_list_27-7 {
  list-style-type: none;
}
ol.lst-kix_list_6-3 {
  list-style-type: none;
}
ul.lst-kix_list_27-8 {
  list-style-type: none;
}
ol.lst-kix_list_6-4 {
  list-style-type: none;
}
.lst-kix_list_24-6 > li:before {
  content: " ";
}
ol.lst-kix_list_6-5 {
  list-style-type: none;
}
.lst-kix_list_23-6 > li:before {
  content: " ";
}
.lst-kix_list_6-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_6-1, lower-latin) ") ";
}
.lst-kix_list_6-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_6-3, upper-latin) ") ";
}
.lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}
.lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}
.lst-kix_list_23-3 > li:before {
  content: " ";
}
.lst-kix_list_23-7 > li:before {
  content: " ";
}
.lst-kix_list_6-0 > li:before {
  content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
}
.lst-kix_list_6-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_6-4, decimal) ") ";
}
.lst-kix_list_23-2 > li:before {
  content: " ";
}
ul.lst-kix_list_16-2 {
  list-style-type: none;
}
ul.lst-kix_list_16-1 {
  list-style-type: none;
}
ul.lst-kix_list_16-0 {
  list-style-type: none;
}
.lst-kix_list_23-0 > li:before {
  content: "" counter(lst-ctn-kix_list_23-0, decimal) ". ";
}
.lst-kix_list_23-8 > li:before {
  content: " ";
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
ol.lst-kix_list_27-0 {
  list-style-type: none;
}
.lst-kix_list_23-1 > li:before {
  content: " ";
}
.lst-kix_list_6-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_6-2, lower-roman) ") ";
}
.lst-kix_list_24-1 > li:before {
  content: " ";
}
ul.lst-kix_list_16-8 {
  list-style-type: none;
}
ul.lst-kix_list_16-7 {
  list-style-type: none;
}
ul.lst-kix_list_16-6 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
ul.lst-kix_list_16-5 {
  list-style-type: none;
}
.lst-kix_list_24-0 > li:before {
  content: "" counter(lst-ctn-kix_list_24-0, decimal) ". ";
}
ul.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_6-8 > li:before {
  content: " ";
}
ul.lst-kix_list_16-3 {
  list-style-type: none;
}
.lst-kix_list_6-5 > li:before {
  content: " ";
}
.lst-kix_list_6-7 > li:before {
  content: " ";
}
.lst-kix_list_23-4 > li:before {
  content: " ";
}
.lst-kix_list_23-5 > li:before {
  content: " ";
}
.lst-kix_list_6-6 > li:before {
  content: " ";
}
.lst-kix_list_7-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_7-4, decimal) ") ";
}
.lst-kix_list_7-6 > li:before {
  content: " ";
}
ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}
.lst-kix_list_22-2 > li:before {
  content: " ";
}
.lst-kix_list_22-6 > li:before {
  content: " ";
}
.lst-kix_list_7-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_7-2, lower-roman) ") ";
}
.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}
.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}
.lst-kix_list_22-0 > li:before {
  content: "" counter(lst-ctn-kix_list_22-0, decimal) ". ";
}
.lst-kix_list_22-8 > li:before {
  content: " ";
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
.lst-kix_list_13-7 > li:before {
  content: " ";
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: " ";
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_23-0.start {
  counter-reset: lst-ctn-kix_list_23-0 0;
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
.lst-kix_list_22-4 > li:before {
  content: " ";
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_15-5 > li:before {
  content: " ";
}
ol.lst-kix_list_26-0.start {
  counter-reset: lst-ctn-kix_list_26-0 0;
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
ol.lst-kix_list_28-0 {
  list-style-type: none;
}
.lst-kix_list_4-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_4-1, lower-latin) ") ";
}
.lst-kix_list_15-7 > li:before {
  content: " ";
}
ul.lst-kix_list_19-7 {
  list-style-type: none;
}
ul.lst-kix_list_19-6 {
  list-style-type: none;
}
.lst-kix_list_4-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_4-3, upper-latin) ") ";
}
.lst-kix_list_4-5 > li:before {
  content: " ";
}
ul.lst-kix_list_19-5 {
  list-style-type: none;
}
ul.lst-kix_list_19-4 {
  list-style-type: none;
}
ul.lst-kix_list_19-3 {
  list-style-type: none;
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
.lst-kix_list_25-5 > li:before {
  content: " ";
}
ul.lst-kix_list_19-2 {
  list-style-type: none;
}
ul.lst-kix_list_19-1 {
  list-style-type: none;
}
ul.lst-kix_list_19-0 {
  list-style-type: none;
}
.lst-kix_list_15-1 > li:before {
  content: " ";
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.lst-kix_list_25-7 > li:before {
  content: " ";
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.lst-kix_list_15-3 > li:before {
  content: " ";
}
ul.lst-kix_list_19-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
.lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}
.lst-kix_list_30-2 > li {
  counter-increment: lst-ctn-kix_list_30-2;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_20-0 > li {
  counter-increment: lst-ctn-kix_list_20-0;
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
.lst-kix_list_30-3 > li {
  counter-increment: lst-ctn-kix_list_30-3;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
ol.lst-kix_list_8-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li:before {
  content: " ";
}
ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}
ol.lst-kix_list_8-4 {
  list-style-type: none;
}
.lst-kix_list_12-1 > li:before {
  content: " ";
}
ul.lst-kix_list_29-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-5 {
  list-style-type: none;
}
ul.lst-kix_list_29-2 {
  list-style-type: none;
}
ol.lst-kix_list_8-6 {
  list-style-type: none;
}
ul.lst-kix_list_29-3 {
  list-style-type: none;
}
ol.lst-kix_list_8-7 {
  list-style-type: none;
}
ul.lst-kix_list_29-4 {
  list-style-type: none;
}
ol.lst-kix_list_8-0 {
  list-style-type: none;
}
ul.lst-kix_list_29-5 {
  list-style-type: none;
}
ol.lst-kix_list_8-1 {
  list-style-type: none;
}
ul.lst-kix_list_29-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_29-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_29-8 {
  list-style-type: none;
}
.lst-kix_list_13-3 > li:before {
  content: " ";
}
ol.lst-kix_list_29-0 {
  list-style-type: none;
}
ul.lst-kix_list_18-0 {
  list-style-type: none;
}
ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}
.lst-kix_list_28-0 > li {
  counter-increment: lst-ctn-kix_list_28-0;
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_13-5 > li:before {
  content: " ";
}
.lst-kix_list_12-5 > li:before {
  content: " ";
}
ul.lst-kix_list_18-8 {
  list-style-type: none;
}
ul.lst-kix_list_18-7 {
  list-style-type: none;
}
ul.lst-kix_list_18-6 {
  list-style-type: none;
}
ul.lst-kix_list_18-5 {
  list-style-type: none;
}
.lst-kix_list_12-7 > li:before {
  content: " ";
}
ul.lst-kix_list_18-4 {
  list-style-type: none;
}
ul.lst-kix_list_18-3 {
  list-style-type: none;
}
ul.lst-kix_list_18-2 {
  list-style-type: none;
}
ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}
ul.lst-kix_list_18-1 {
  list-style-type: none;
}
ol.lst-kix_list_29-0.start {
  counter-reset: lst-ctn-kix_list_29-0 0;
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
.lst-kix_list_13-1 > li:before {
  content: " ";
}
ol.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
.lst-kix_list_30-5 > li:before {
  content: " ";
}
.lst-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ul.lst-kix_list_24-1 {
  list-style-type: none;
}
ul.lst-kix_list_24-2 {
  list-style-type: none;
}
ul.lst-kix_list_24-3 {
  list-style-type: none;
}
ul.lst-kix_list_24-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_24-5 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.lst-kix_list_30-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_30-1, lower-latin) ") ";
}
.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, decimal) ". ";
}
.lst-kix_list_30-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_30-2, lower-roman) ") ";
}
.lst-kix_list_21-8 > li:before {
  content: " ";
}
.lst-kix_list_26-5 > li:before {
  content: " ";
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
ul.lst-kix_list_24-6 {
  list-style-type: none;
}
ul.lst-kix_list_24-7 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_3-4, decimal) ") ";
}
ul.lst-kix_list_24-8 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_3-3, upper-latin) ") ";
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
.lst-kix_list_26-8 > li:before {
  content: " ";
}
.lst-kix_list_8-0 > li:before {
  content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
.lst-kix_list_30-6 > li:before {
  content: " ";
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: " ";
}
.lst-kix_list_3-8 > li:before {
  content: " ";
}
.lst-kix_list_21-0 > li:before {
  content: "" counter(lst-ctn-kix_list_21-0, decimal) ". ";
}
.lst-kix_list_26-1 > li:before {
  content: " ";
}
.lst-kix_list_21-1 > li:before {
  content: " ";
}
.lst-kix_list_8-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_8-3, upper-latin) ") ";
}
ul.lst-kix_list_13-5 {
  list-style-type: none;
}
ul.lst-kix_list_13-4 {
  list-style-type: none;
}
ul.lst-kix_list_13-3 {
  list-style-type: none;
}
ul.lst-kix_list_13-2 {
  list-style-type: none;
}
ul.lst-kix_list_13-1 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
  content: " ";
}
ul.lst-kix_list_13-0 {
  list-style-type: none;
}
.lst-kix_list_26-4 > li:before {
  content: " ";
}
.lst-kix_list_8-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_8-4, decimal) ") ";
}
ol.lst-kix_list_22-0 {
  list-style-type: none;
}
ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}
ul.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_11-1 > li:before {
  content: " ";
}
ul.lst-kix_list_13-7 {
  list-style-type: none;
}
ul.lst-kix_list_13-6 {
  list-style-type: none;
}
.lst-kix_list_21-5 > li:before {
  content: " ";
}
.lst-kix_list_21-4 > li:before {
  content: " ";
}
.lst-kix_list_11-0 > li:before {
  content: "\0025cf  ";
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_26-0 > li:before {
  content: "" counter(lst-ctn-kix_list_26-0, decimal) ". ";
}
.lst-kix_list_8-8 > li:before {
  content: " ";
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_16-8 > li:before {
  content: " ";
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_23-1 {
  list-style-type: none;
}
.lst-kix_list_16-7 > li:before {
  content: " ";
}
ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
ul.lst-kix_list_23-2 {
  list-style-type: none;
}
ul.lst-kix_list_23-3 {
  list-style-type: none;
}
ul.lst-kix_list_23-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_23-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_23-6 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
  content: " ";
}
.lst-kix_list_4-7 > li:before {
  content: " ";
}
ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 0;
}
.lst-kix_list_17-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_16-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_21-0 > li {
  counter-increment: lst-ctn-kix_list_21-0;
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
.lst-kix_list_25-1 > li:before {
  content: " ";
}
.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}
ul.lst-kix_list_23-7 {
  list-style-type: none;
}
.lst-kix_list_25-0 > li:before {
  content: "" counter(lst-ctn-kix_list_25-0, decimal) ". ";
}
.lst-kix_list_16-4 > li:before {
  content: " ";
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
ul.lst-kix_list_23-8 {
  list-style-type: none;
}
.lst-kix_list_16-3 > li:before {
  content: " ";
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_30-1 > li {
  counter-increment: lst-ctn-kix_list_30-1;
}
ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}
ul.lst-kix_list_12-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}
ul.lst-kix_list_12-5 {
  list-style-type: none;
}
.lst-kix_list_17-7 > li:before {
  content: " ";
}
ul.lst-kix_list_12-4 {
  list-style-type: none;
}
ul.lst-kix_list_12-3 {
  list-style-type: none;
}
ul.lst-kix_list_12-2 {
  list-style-type: none;
}
ul.lst-kix_list_12-1 {
  list-style-type: none;
}
.lst-kix_list_17-8 > li:before {
  content: " ";
}
ul.lst-kix_list_12-0 {
  list-style-type: none;
}
.lst-kix_list_17-3 > li:before {
  content: " ";
}
ol.lst-kix_list_23-0 {
  list-style-type: none;
}
.lst-kix_list_17-4 > li:before {
  content: " ";
}
ul.lst-kix_list_12-8 {
  list-style-type: none;
}
ul.lst-kix_list_12-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
.lst-kix_list_7-0 > li:before {
  content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
ol.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
ol.lst-kix_list_5-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
ol.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_22-5 > li:before {
  content: " ";
}
.lst-kix_list_2-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_2-4, decimal) ") ";
}
.lst-kix_list_2-8 > li:before {
  content: " ";
}
ul.lst-kix_list_26-1 {
  list-style-type: none;
}
ul.lst-kix_list_26-2 {
  list-style-type: none;
}
ul.lst-kix_list_26-3 {
  list-style-type: none;
}
.lst-kix_list_22-1 > li:before {
  content: " ";
}
.lst-kix_list_27-4 > li:before {
  content: " ";
}
.lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}
.lst-kix_list_7-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_7-3, upper-latin) ") ";
}
.lst-kix_list_10-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
.lst-kix_list_13-8 > li:before {
  content: " ";
}
.lst-kix_list_18-3 > li:before {
  content: " ";
}
.lst-kix_list_18-7 > li:before {
  content: " ";
}
ol.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_26-4 {
  list-style-type: none;
}
ol.lst-kix_list_5-8 {
  list-style-type: none;
}
ul.lst-kix_list_26-5 {
  list-style-type: none;
}
ul.lst-kix_list_26-6 {
  list-style-type: none;
}
ul.lst-kix_list_26-7 {
  list-style-type: none;
}
ol.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}
ul.lst-kix_list_26-8 {
  list-style-type: none;
}
ol.lst-kix_list_5-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
.lst-kix_list_27-0 > li:before {
  content: "" counter(lst-ctn-kix_list_27-0, decimal) ". ";
}
ol.lst-kix_list_5-5 {
  list-style-type: none;
}
ol.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: " ";
}
ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}
.lst-kix_list_15-4 > li:before {
  content: " ";
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
.lst-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}
.lst-kix_list_10-4 > li:before {
  content: " ";
}
.lst-kix_list_10-8 > li:before {
  content: " ";
}
.lst-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
}
.lst-kix_list_27-0 > li {
  counter-increment: lst-ctn-kix_list_27-0;
}
ul.lst-kix_list_15-3 {
  list-style-type: none;
}
ul.lst-kix_list_15-2 {
  list-style-type: none;
}
.lst-kix_list_15-0 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_list_15-1 {
  list-style-type: none;
}
.lst-kix_list_15-8 > li:before {
  content: " ";
}
ul.lst-kix_list_15-0 {
  list-style-type: none;
}
ol.lst-kix_list_24-0 {
  list-style-type: none;
}
.lst-kix_list_4-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_4-4, decimal) ") ";
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.lst-kix_list_25-4 > li:before {
  content: " ";
}
ul.lst-kix_list_15-8 {
  list-style-type: none;
}
ul.lst-kix_list_15-7 {
  list-style-type: none;
}
ul.lst-kix_list_15-6 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_9-3, upper-latin) ") ";
}
ul.lst-kix_list_15-5 {
  list-style-type: none;
}
ul.lst-kix_list_15-4 {
  list-style-type: none;
}
ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}
.lst-kix_list_25-8 > li:before {
  content: " ";
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
ul.lst-kix_list_25-1 {
  list-style-type: none;
}
ul.lst-kix_list_25-2 {
  list-style-type: none;
}
.lst-kix_list_9-7 > li:before {
  content: " ";
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
.lst-kix_list_29-4 > li:before {
  content: " ";
}
.lst-kix_list_29-8 > li:before {
  content: " ";
}
ul.lst-kix_list_25-3 {
  list-style-type: none;
}
ul.lst-kix_list_25-4 {
  list-style-type: none;
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
.lst-kix_list_11-4 > li:before {
  content: " ";
}
.lst-kix_list_12-4 > li:before {
  content: " ";
}
.lst-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}
.lst-kix_list_20-5 > li:before {
  content: " ";
}
.lst-kix_list_29-0 > li:before {
  content: "" counter(lst-ctn-kix_list_29-0, decimal) ". ";
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}
.lst-kix_list_28-8 > li:before {
  content: " ";
}
ul.lst-kix_list_25-5 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}
ul.lst-kix_list_25-6 {
  list-style-type: none;
}
.lst-kix_list_20-1 > li:before {
  content: " ";
}
ul.lst-kix_list_25-7 {
  list-style-type: none;
}
ul.lst-kix_list_25-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
.lst-kix_list_11-8 > li:before {
  content: " ";
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: "\0025cf  ";
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}
.lst-kix_list_28-4 > li:before {
  content: " ";
}
.lst-kix_list_1-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_1-4, decimal) ") ";
}
.lst-kix_list_13-0 > li:before {
  content: "\0025cf  ";
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
ol.lst-kix_list_25-0.start {
  counter-reset: lst-ctn-kix_list_25-0 0;
}
ul.lst-kix_list_14-4 {
  list-style-type: none;
}
ul.lst-kix_list_14-3 {
  list-style-type: none;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
ul.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li:before {
  content: " ";
}
ul.lst-kix_list_14-1 {
  list-style-type: none;
}
ul.lst-kix_list_14-0 {
  list-style-type: none;
}
ol.lst-kix_list_25-0 {
  list-style-type: none;
}
ul.lst-kix_list_14-8 {
  list-style-type: none;
}
ul.lst-kix_list_14-7 {
  list-style-type: none;
}
.lst-kix_list_2-0 > li:before {
  content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}
ul.lst-kix_list_14-6 {
  list-style-type: none;
}
ul.lst-kix_list_14-5 {
  list-style-type: none;
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst-kix_list_28-0 > li:before {
  content: "" counter(lst-ctn-kix_list_28-0, decimal) ". ";
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: " ";
}
.lst-kix_list_27-8 > li:before {
  content: " ";
}
.lst-kix_list_12-8 > li:before {
  content: " ";
}
.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
ul.lst-kix_list_20-2 {
  list-style-type: none;
}
ul.lst-kix_list_20-3 {
  list-style-type: none;
}
.lst-kix_list_19-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_19-1 > li:before {
  content: " ";
}
ul.lst-kix_list_20-4 {
  list-style-type: none;
}
ul.lst-kix_list_20-5 {
  list-style-type: none;
}
ul.lst-kix_list_20-6 {
  list-style-type: none;
}
ul.lst-kix_list_20-7 {
  list-style-type: none;
}
ul.lst-kix_list_20-8 {
  list-style-type: none;
}
.lst-kix_list_19-4 > li:before {
  content: " ";
}
.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}
ol.lst-kix_list_30-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}
ol.lst-kix_list_30-5 {
  list-style-type: none;
}
.lst-kix_list_19-2 > li:before {
  content: " ";
}
.lst-kix_list_19-3 > li:before {
  content: " ";
}
ol.lst-kix_list_30-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
ol.lst-kix_list_21-0.start {
  counter-reset: lst-ctn-kix_list_21-0 0;
}
ol.lst-kix_list_30-7 {
  list-style-type: none;
}
ul.lst-kix_list_20-1 {
  list-style-type: none;
}
ol.lst-kix_list_30-2 {
  list-style-type: none;
}
ol.lst-kix_list_30-1 {
  list-style-type: none;
}
ol.lst-kix_list_30-4 {
  list-style-type: none;
}
ol.lst-kix_list_30-3 {
  list-style-type: none;
}
ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}
ol.lst-kix_list_30-0 {
  list-style-type: none;
}
.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_19-8 > li:before {
  content: " ";
}
.lst-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}
.lst-kix_list_19-5 > li:before {
  content: " ";
}
.lst-kix_list_19-6 > li:before {
  content: " ";
}
.lst-kix_list_19-7 > li:before {
  content: " ";
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}
.lst-kix_list_22-0 > li {
  counter-increment: lst-ctn-kix_list_22-0;
}
ol.lst-kix_list_24-0.start {
  counter-reset: lst-ctn-kix_list_24-0 0;
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_18-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_18-1 > li:before {
  content: " ";
}
.lst-kix_list_18-2 > li:before {
  content: " ";
}
.lst-kix_list_26-0 > li {
  counter-increment: lst-ctn-kix_list_26-0;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
ol.lst-kix_list_27-0.start {
  counter-reset: lst-ctn-kix_list_27-0 0;
}
.lst-kix_list_30-4 > li {
  counter-increment: lst-ctn-kix_list_30-4;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-7 > li:before {
  content: " ";
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.lst-kix_list_27-1 > li:before {
  content: " ";
}
ul.lst-kix_list_22-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_22-2 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
ul.lst-kix_list_22-3 {
  list-style-type: none;
}
ul.lst-kix_list_22-4 {
  list-style-type: none;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: " ";
}
.lst-kix_list_27-3 > li:before {
  content: " ";
}
ul.lst-kix_list_22-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_22-6 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_22-7 {
  list-style-type: none;
}
.lst-kix_list_27-5 > li:before {
  content: " ";
}
.lst-kix_list_18-6 > li:before {
  content: " ";
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
.lst-kix_list_10-1 > li:before {
  content: " ";
}
.lst-kix_list_18-4 > li:before {
  content: " ";
}
.lst-kix_list_18-8 > li:before {
  content: " ";
}
ul.lst-kix_list_22-8 {
  list-style-type: none;
}
ol.lst-kix_list_30-1.start {
  counter-reset: lst-ctn-kix_list_30-1 0;
}
.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: " ";
}
.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}
.lst-kix_list_10-5 > li:before {
  content: " ";
}
.lst-kix_list_10-3 > li:before {
  content: " ";
}
ul.lst-kix_list_11-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-6 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}
ul.lst-kix_list_11-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-3 {
  list-style-type: none;
}
ul.lst-kix_list_11-2 {
  list-style-type: none;
}
ul.lst-kix_list_11-1 {
  list-style-type: none;
}
ul.lst-kix_list_11-0 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_9-2, lower-roman) ") ";
}
ol.lst-kix_list_20-0 {
  list-style-type: none;
}
ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}
ul.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_20-8 > li:before {
  content: " ";
}
.lst-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}
.lst-kix_list_9-0 > li:before {
  content: "" counter(lst-ctn-kix_list_9-0, decimal) ". ";
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
.lst-kix_list_29-7 > li:before {
  content: " ";
}
ul.lst-kix_list_21-1 {
  list-style-type: none;
}
ul.lst-kix_list_21-2 {
  list-style-type: none;
}
ul.lst-kix_list_21-3 {
  list-style-type: none;
}
ul.lst-kix_list_21-4 {
  list-style-type: none;
}
.lst-kix_list_29-5 > li:before {
  content: " ";
}
.lst-kix_list_20-0 > li:before {
  content: "" counter(lst-ctn-kix_list_20-0, decimal) ". ";
}
ul.lst-kix_list_21-5 {
  list-style-type: none;
}
ul.lst-kix_list_21-6 {
  list-style-type: none;
}
ul.lst-kix_list_21-7 {
  list-style-type: none;
}
ul.lst-kix_list_21-8 {
  list-style-type: none;
}
.lst-kix_list_9-6 > li:before {
  content: " ";
}
.lst-kix_list_9-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_9-4, decimal) ") ";
}
.lst-kix_list_30-8 > li {
  counter-increment: lst-ctn-kix_list_30-8;
}
.lst-kix_list_11-3 > li:before {
  content: " ";
}
.lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}
.lst-kix_list_20-6 > li:before {
  content: " ";
}
.lst-kix_list_23-0 > li {
  counter-increment: lst-ctn-kix_list_23-0;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.lst-kix_list_29-1 > li:before {
  content: " ";
}
.lst-kix_list_20-4 > li:before {
  content: " ";
}
.lst-kix_list_11-5 > li:before {
  content: " ";
}
ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}
.lst-kix_list_29-3 > li:before {
  content: " ";
}
.lst-kix_list_20-2 > li:before {
  content: " ";
}
.lst-kix_list_9-8 > li:before {
  content: " ";
}
.lst-kix_list_1-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_1-1, lower-latin) ") ";
}
.lst-kix_list_11-7 > li:before {
  content: " ";
}
ul.lst-kix_list_10-0 {
  list-style-type: none;
}
.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}
.lst-kix_list_1-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_1-3, upper-latin) ") ";
}
.lst-kix_list_28-3 > li:before {
  content: " ";
}
.lst-kix_list_28-7 > li:before {
  content: " ";
}
ul.lst-kix_list_10-8 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
ul.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: " ";
}
ul.lst-kix_list_10-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
ul.lst-kix_list_10-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-4 {
  list-style-type: none;
}
ul.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: " ";
}
ul.lst-kix_list_10-2 {
  list-style-type: none;
}
.lst-kix_list_28-5 > li:before {
  content: " ";
}
ul.lst-kix_list_10-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
.lst-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}
ol.lst-kix_list_21-0 {
  list-style-type: none;
}
ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}
.lst-kix_list_2-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") ";
}
.lst-kix_list_27-7 > li:before {
  content: " ";
}
.lst-kix_list_2-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_2-3, upper-latin) ") ";
}
.lst-kix_list_28-1 > li:before {
  content: " ";
}
.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
.lst-kix_list_30-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_30-3, upper-latin) ") ";
}
.lst-kix_list_30-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_30-4, decimal) ") ";
}
.lst-kix_list_30-8 > li:before {
  content: " ";
}
.lst-kix_list_30-5 > li {
  counter-increment: lst-ctn-kix_list_30-5;
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}
.lst-kix_list_3-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_3-1, lower-latin) ") ";
}
.lst-kix_list_3-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_3-2, lower-roman) ") ";
}
.lst-kix_list_26-6 > li:before {
  content: " ";
}
.lst-kix_list_26-7 > li:before {
  content: " ";
}
.lst-kix_list_8-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_8-1, lower-latin) ") ";
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.lst-kix_list_8-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_8-2, lower-roman) ") ";
}
.lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}
.lst-kix_list_3-5 > li:before {
  content: " ";
}
.lst-kix_list_30-7 > li:before {
  content: " ";
}
ol.lst-kix_list_30-8.start {
  counter-reset: lst-ctn-kix_list_30-8 0;
}
.lst-kix_list_21-2 > li:before {
  content: " ";
}
.lst-kix_list_8-5 > li:before {
  content: " ";
}
.lst-kix_list_8-6 > li:before {
  content: " ";
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
ol.lst-kix_list_28-0.start {
  counter-reset: lst-ctn-kix_list_28-0 0;
}
.lst-kix_list_26-2 > li:before {
  content: " ";
}
.lst-kix_list_26-3 > li:before {
  content: " ";
}
.lst-kix_list_3-6 > li:before {
  content: " ";
}
.lst-kix_list_21-6 > li:before {
  content: " ";
}
.lst-kix_list_21-7 > li:before {
  content: " ";
}
ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
.lst-kix_list_11-2 > li:before {
  content: " ";
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.lst-kix_list_21-3 > li:before {
  content: " ";
}
ol.lst-kix_list_30-2.start {
  counter-reset: lst-ctn-kix_list_30-2 0;
}
.lst-kix_list_16-6 > li:before {
  content: " ";
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}
.lst-kix_list_25-2 > li:before {
  content: " ";
}
.lst-kix_list_17-1 > li:before {
  content: " ";
}
.lst-kix_list_25-3 > li:before {
  content: " ";
}
ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}
.lst-kix_list_16-1 > li:before {
  content: " ";
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}
.lst-kix_list_25-0 > li {
  counter-increment: lst-ctn-kix_list_25-0;
}
.lst-kix_list_16-2 > li:before {
  content: " ";
}
.lst-kix_list_16-5 > li:before {
  content: " ";
}
ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}
ol.lst-kix_list_30-3.start {
  counter-reset: lst-ctn-kix_list_30-3 0;
}
.lst-kix_list_30-7 > li {
  counter-increment: lst-ctn-kix_list_30-7;
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}
.lst-kix_list_17-2 > li:before {
  content: " ";
}
ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}
.lst-kix_list_30-0 > li:before {
  content: "" counter(lst-ctn-kix_list_30-0, decimal) ". ";
}
.lst-kix_list_17-6 > li:before {
  content: " ";
}
.lst-kix_list_17-5 > li:before {
  content: " ";
}
.lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}
.lst-kix_list_2-6 > li:before {
  content: " ";
}
.lst-kix_list_27-2 > li:before {
  content: " ";
}
.lst-kix_list_22-3 > li:before {
  content: " ";
}
.lst-kix_list_7-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_7-1, lower-latin) ") ";
}
.lst-kix_list_7-5 > li:before {
  content: " ";
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
.lst-kix_list_27-6 > li:before {
  content: " ";
}
.lst-kix_list_22-7 > li:before {
  content: " ";
}
ol.lst-kix_list_30-4.start {
  counter-reset: lst-ctn-kix_list_30-4 0;
}
.lst-kix_list_30-0 > li {
  counter-increment: lst-ctn-kix_list_30-0;
}
ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}
.lst-kix_list_18-5 > li:before {
  content: " ";
}
.lst-kix_list_13-6 > li:before {
  content: " ";
}
ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}
.lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}
.lst-kix_list_15-6 > li:before {
  content: " ";
}
.lst-kix_list_29-0 > li {
  counter-increment: lst-ctn-kix_list_29-0;
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}
.lst-kix_list_10-2 > li:before {
  content: " ";
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_20-7 > li:before {
  content: " ";
}
ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}
.lst-kix_list_4-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_4-2, lower-roman) ") ";
}
.lst-kix_list_4-6 > li:before {
  content: " ";
}
.lst-kix_list_15-2 > li:before {
  content: " ";
}
.lst-kix_list_25-6 > li:before {
  content: " ";
}
.lst-kix_list_10-6 > li:before {
  content: " ";
}
.lst-kix_list_9-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_9-1, lower-latin) ") ";
}
ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}
.lst-kix_list_9-5 > li:before {
  content: " ";
}
.lst-kix_list_29-6 > li:before {
  content: " ";
}
ol.lst-kix_list_22-0.start {
  counter-reset: lst-ctn-kix_list_22-0 0;
}
.lst-kix_list_24-0 > li {
  counter-increment: lst-ctn-kix_list_24-0;
}
.lst-kix_list_12-2 > li:before {
  content: " ";
}
.lst-kix_list_11-6 > li:before {
  content: " ";
}
.lst-kix_list_20-3 > li:before {
  content: " ";
}
ol.lst-kix_list_30-6.start {
  counter-reset: lst-ctn-kix_list_30-6 0;
}
.lst-kix_list_1-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_1-2, lower-roman) ") ";
}
.lst-kix_list_29-2 > li:before {
  content: " ";
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}
.lst-kix_list_28-6 > li:before {
  content: " ";
}
.lst-kix_list_1-6 > li:before {
  content: " ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_12-6 > li:before {
  content: " ";
}
ol.lst-kix_list_30-7.start {
  counter-reset: lst-ctn-kix_list_30-7 0;
}
.lst-kix_list_2-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_2-2, lower-roman) ") ";
}
.lst-kix_list_13-2 > li:before {
  content: " ";
}
.lst-kix_list_28-2 > li:before {
  content: " ";
}
ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c5 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c7 {
  margin-left: 54pt;
  padding-top: 0pt;
  text-indent: -18pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c8 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.c11 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}
.c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}
.c2 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Calibri";
}
.c12 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c4 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c9 {
  background-color: #ffffff;
  max-width: 700pt;
  padding: 72pt 72pt 72pt 72pt;
  margin: auto
}
.c3 {
  font-size: 11pt;
  font-family: "Calibri";
  font-weight: 700;
}
.c10 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c1 {
  font-size: 11pt;
  font-family: "Calibri";
  font-weight: 400;
}
.c6 {
  padding: 0;
  margin: 0;
}
.title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 24pt;
  font-family: "Times";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.subtitle {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 24pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
li {
  color: #000000;
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  /* font-family: "Times New Roman"; */
}
h1 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  /* font-family: "Times New Roman"; */
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Times New Roman";
  line-height: 1;
  text-align: left;
}
h5 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 0pt;
  color: #000000;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Times New Roman";
  line-height: 1;
  text-align: left;
}